<template>
  <v-container class="page">
    <div class="page-header pb-2">
      <v-btn @click="close()" icon color="blue darken-2">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <div class="page-content">
      <div class="mt-2 text-h4">Chapter Completed</div>
      <div class="mt-6">You’ve found</div>
      <div class="mt-2 text-h6">3 Vocabularies!</div>
      <v-row class="mt-5">
        <v-col cols="4">
          <v-img
            :src="require('@/mockups/ABC/book-1/assets/images/1 1.png')"
            max-width="100"
          >
          </v-img>
        </v-col>
        <v-col cols="4">
          <v-img
            :src="require('@/mockups/ABC/book-1/assets/images/1 2.png')"
            max-width="100"
          >
          </v-img>
        </v-col>
        <v-col cols="4">
          <v-img
            :src="require('@/mockups/ABC/book-1/assets/images/0 2.png')"
            max-width="100"
          >
          </v-img>
        </v-col>
      </v-row>

      <v-btn color="blue darken-2" class="mt-8" depressed dark @click="close">
        Yeah
      </v-btn>
    </div>
    <div class="mb-8"></div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    autoplay: false,
    language: 1,
    timer: null,
  }),
  created() {
    //
  },
  mounted() {
    if (this.$route.query.lang) {
      this.language = this.$route.query.lang;
    }

    this.autoplay =
      this.$route.query.autoplay && this.$route.query.autoplay == 1
        ? true
        : false;

    this.timer = setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
  methods: {
    goPrevious() {
      this.$router.push({
        name: "ABCPage9",
        query: { autoplay: this.autoplay ? 1 : 0, lang: this.language },
      });
    },
    goNext() {
      this.$router.push({
        name: this.answer == 3 ? "ABCPage11" : "ABCPage12",
        query: { autoplay: this.autoplay ? 1 : 0, lang: this.language },
      });
    },
    close() {
      this.$router.push({
        name: "ABCPage17",
      });
    },
  },
};
</script>

<style scoped>
.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v-img-x {
  width: 100% !important;
  height: auto;
}
.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: #000 solid 1px;

  position: fixed;
  z-index: 99;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  max-width: 480px;
}

.page {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border: #000 solid 1px;
  background: rgb(255, 255, 255) !important;
}

.page-x {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: #000 solid 1px;
}

.buttons {
  display: flex;
}

.btn-dis {
  opacity: 0.5;
}

.vocab {
  text-decoration: underline;
  font-weight: 900;
}
</style>